@media (min-width: 640px) {
    .platinum-img {
        width: 180px;
        height: 40px;
    }
} 

@media (min-width: 768px) { 
    .platinum-img {
        width: 180px;
        height: 70px;
    }
}

@media (min-width: 1024px) { 
    .platinum-img {
        width: 180px;
        height: 70px;
    }
}

@media (min-width: 1280px) { 
    .platinum-img {
        width: 180px;
        height: 70px;
    }
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
    .qr-flex-direction{
        flex-direction: column !important;
    }
    .rideDetailsMap{
        border-radius: 15px !important
    }
    .qr-wrap-reverse{
        flex-wrap: wrap-reverse !important;
    }
    .qr-margin-top{
        margin-top: 20px !important;
    }
    .qr-app-btn{
        width: 100% !important;
        margin-right: 0px !important;
    }
    .qr-h1{
        font-size: 24px !important;
    }
    .rideDetails.luxyGreenBussiness{
        top: 160px !important
    }
    .ride-booking-pane{
        padding: 1rem !important;
    }
    .qr-web-view {
        display: block !important;
    }
    .qr-h2{
        font-size: 18px !important;
    }
    .qr-h3{
        font-size: 14px !important;
    }
    .treeview_home{
        height: calc(100% - 215px) !important;
    }
}

@media only screen and (min-width: 320px) and (max-width: 380px) {
    .rideDetails.luxyGreenBussiness{
        top: 220px !important
    }
}

@media only screen and (min-width: 385px) and (max-width: 713px) {
    .rideDetails.luxyGreenBussiness{
        top: 180px !important
    }
}

.qr-app-btn{
    color: white !important;
    background-color: black !important;
}

.ride-booking-pane{
    padding: 3rem;
}

.qr-web-view {
    display: none;
}

.cancel-button {
    /* width: 280px;
  height: 34px; */
    background: #6c757d 0% 0% no-repeat padding-box;
    border-radius: 4px;
    color: #fff;
}
.cancel-button:hover {
    color: #fff;
}
input[type=checkbox], input[type=radio], input[name=booking_for] {
    margin-top: 0.5rem;
}